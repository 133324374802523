/**
 * 乗務判断結果ページ用のスクリプト
 */
if (is_route('drive_judgement.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        drive_judgement_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.drive_judgement_event_set = function () {

    // 自動リロード用
    let setTimeoutId;

    /*---- アクションボタン押下（承認・拒否） ----*/

    // 詳細 表示時
    $(document).on('click', '[data-setting-modal]', function () {
        // リロードOFF
        clearTimeout(setTimeoutId);
        _modal.create(this, 'setting-modal', ['id', 'status'], false);
    });

    // モーダル 閉じる
    $('#drive_judgement_setting-modal, #drive_judgement-code-modal').on('hide.bs.modal', function () {
        // 値によってリロード再開
        switchAutoLoad();
    });

    // 詳細 保存押下
    $(document).on('click', '[data-drive_judgement_save_modal]', function () {
        _modal.submit(this, 'drive_judgement_save_modal');
    });

    /*---- 認証コードボタン押下 ----*/

    // 認証コード 表示時
    $(document).on('click', '[data-code-modal]', function () {
        // リロードOFF
        clearTimeout(setTimeoutId);
        _modal.create(this, 'code-modal', ['id']);
    });

    /*---- 自動リロード切替 ----*/

    // リロード実行設定
    switchAutoLoad();

    // 自動リロードON/OFF 押下時
    $(document).on('click', '[name=auto_reload]', function (e) {
        // クリックでONする場合は、すぐにリロード実行
        let timer = 0;
        switchAutoLoad(timer);
    });

    // 自動リロード切替
    function switchAutoLoad(timer = 300000) {
        // デフォルト：5分間隔（300000ミリ秒）でリロード
        if ($('[name=auto_reload]:checked').val() === '1') {
            // ON
            setTimeoutId = setTimeout(function () {
                location.reload();
            }, timer);
        } else {
            // OFF
            clearTimeout(setTimeoutId);
        }
    }
};

/**
 * 点呼ページ用のスクリプト
 */
if (is_route('rollcall.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        rollcall_event_set();
        rollcall_detail_event_set();
        rollcall_config_event_set();
        rollcall_refresh_approval_rate();
    });
}

/** --------------------------------------
 * 印刷用日次情報制御
 * 各functionで使用するためGlobal定義
 -------------------------------------- */

var dailyApproval = {
    /*---- 印刷用日次情報要素の親ID ----*/
    id: '#rollcall-detail-modal',

    /*---- 保存済み印刷用日次情報 ----*/
    result: '',

    /*---- 営業所選択判断 ----*/
    isPost: function() {
        return $('#rollcall-detail-modal select[name="section_id"]').val().length > 0
    },

    /*---- パラメタ編集 ----*/
    setParam: function(data = {}) {
        dailyApproval.data = {
            'section_id': $('#rollcall-detail-modal select[name="section_id"]').val(),
            'approval_date': $('#rollcall-detail-modal input[name="date"]').val(),
        }
        return Object.assign(dailyApproval.data, data);
    },

    /*---- 印刷用日次情報取得 ----*/
    postGet:function() {
        let action = $('#rollcall-daily-approval-block').data('action_get');
        let param = dailyApproval.setParam();
        // POST
        _ajax.post(action, param, function (results) {
            dailyApproval.setSelect(results);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    },

    /*---- 印刷用日次情報保存 ----*/
    postSave:function(data) {
        let action = $('#rollcall-daily-approval-block').data('action_save');
        let param = dailyApproval.setParam(data);
        // POST
        _ajax.post(action, param, function (results) {
            // 特に何もしない
            // 完了メッセージをセットする場合は、以下をイキにする
            // _complete.set(results);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    },

    /*---- 保存済み印刷用日次情報の選択済を制御 ----*/
    setSelect:function(results) {
        dailyApproval.result = results.result;
        // user用select2が生成済かどうかを監視
        let interval = setInterval(function () {
            if ($('#select2-print-form-print_general_id-container').length > 0
                && $('#select2-print-form-print_operator_id-container').length > 0
                && $('#select2-print-form-print_assistant_id-container').length > 0
                && $('#select2-print-form-weather-container').length > 0) {
                dailyApproval.clearEvent();
                // 印刷用日次情報が保存されている場合はselect2選択済みにする
                if (dailyApproval.result !== '') {
                    let relationNames = {
                        'print_general_id' : 'general_user',
                        'print_operator_id' : 'operator_user',
                        'print_assistant_id' : 'assistant_user',
                    }
                    // 削除済みのユーザーで、選択肢に存在しなければ追加する
                    $.each(['print_general_id', 'print_operator_id', 'print_assistant_id'], function (index, value) {
                        let element = $(dailyApproval.id + ' select[name="' + value + '"]');
                        // リレーション取得
                        let printUserId = dailyApproval.result[value];
                        // オプションにユーザーIDが存在するか
                        if (element.find("option[value='" + printUserId + "']").length === 0) {
                            // リレーション取得
                            let relationName = relationNames[value];
                            let deletedUserInfo = dailyApproval.result[relationName];
                            if (!deletedUserInfo) {
                                return;
                            }
                            let newOption = new Option(deletedUserInfo.user_name, deletedUserInfo.id, true, true);
                            // 選択肢追加
                            element.append(newOption).trigger('change');
                        }
                    });
                    $(dailyApproval.id + ' #print-form-is_normal_alcohol_detector_' + dailyApproval.result.is_normal_alcohol_detector).prop('checked', true)
                        .parent('label').addClass('active');
                    $(dailyApproval.id + ' select[name="print_general_id"]').val(dailyApproval.result.print_general_id).trigger('change');
                    $(dailyApproval.id + ' select[name="print_operator_id"]').val(dailyApproval.result.print_operator_id).trigger('change');
                    $(dailyApproval.id + ' select[name="print_assistant_id"]').val(dailyApproval.result.print_assistant_id).trigger('change');
                    $(dailyApproval.id + ' select[name="weather"]').val(dailyApproval.result.weather).trigger('change');
                }
                dailyApproval.setEvent();
                clearInterval(interval);
            }
        }, 100);
    },

    /*---- イベントクリア ----*/
    clearEvent:function() {
        // アルコール検知器状況変更時
        $(dailyApproval.id + ' input[name="is_normal_alcohol_detector"]').off("change");
        // 統括運行管理者
        $(dailyApproval.id + ' select[name="print_general_id"]').off("select2:select");
        // 運行管理者
        $(dailyApproval.id + ' select[name="print_operator_id"]').off("select2:select");
        // 補助者
        $(dailyApproval.id + ' select[name="print_assistant_id"]').off("select2:select");
        // 天候
        $(dailyApproval.id + ' select[name="weather"]').off("select2:select");
    },

    /*---- イベント登録 ----*/
    setEvent:function() {
        // アルコール検知器状況変更時
        $(dailyApproval.id + ' input[name="is_normal_alcohol_detector"]').on('change', function() {
            if (!dailyApproval.isPost()) return;
            // 印刷用日次情報保存
            if ($(this).parent().hasClass('active')) {
                dailyApproval.postSave({'is_normal_alcohol_detector': $(this).val()});
            } else {
                dailyApproval.postSave({'is_normal_alcohol_detector': null});
            }
        });
        // 統括運行管理者
        $(dailyApproval.id + ' select[name="print_general_id"]').on('select2:select', function() {
            if (!dailyApproval.isPost() || $(this).val() === dailyApproval.result.print_general_id) return;
            // 印刷用日次情報保存
            dailyApproval.postSave({'print_general_id': $(this).val()});
        });
        // 運行管理者
        $(dailyApproval.id + ' select[name="print_operator_id"]').on('select2:select', function() {
            if (!dailyApproval.isPost() || $(this).val() === dailyApproval.result.print_operator_id) return;
            // 印刷用日次情報保存
            dailyApproval.postSave({'print_operator_id': $(this).val()});
        });
        // 補助者
        $(dailyApproval.id + ' select[name="print_assistant_id"]').on('select2:select', function() {
            if (!dailyApproval.isPost() || $(this).val() === dailyApproval.result.print_assistant_id) return;
            // 印刷用日次情報保存
            dailyApproval.postSave({'print_assistant_id': $(this).val()});
        });
        // 天候
        $(dailyApproval.id + ' select[name="weather"]').on('select2:select', function() {
            if (!dailyApproval.isPost() || $(this).val() === dailyApproval.result.weather) return;
            // 印刷用日次情報保存
            dailyApproval.postSave({'weather': $(this).val()});
        });
    }
}

window.rollcall_refresh_approval_rate = function() {

    var elm = $('.monthly-rate');
    // アクションの取得
    let action = extend_url_query($(elm).data('action'));
    // 検索フォームを配列化
    let datas = $('#search-form').serializeArray();
    _ajax.background(action, datas, function (results) {
        $('.monthly-rate').html(results);
    }, function(xhr, status, errorThrown) {
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
}

/**
 * ページ読み込み時のイベント設定
 */
window.rollcall_event_set = function () {

    // search.jsのイベントが先に実行されるため、一旦offにして再設定
    $(document).off('change', '#search-form, #toolbar-keyword-search');
    // 検索フォームの変更時
    $(document).on('change', '#search-form', function(e) {
        // 変更によってロードしないdata属性がある場合
        if (e.target.getAttribute('data-no-load-self-change')) {
            return;
        }
        // コンテンツを読込
        load_contents(function () {
            // 点呼割更新
            rollcall_refresh_approval_rate();
        }, false);
    });

    /*---- 点呼詳細表示時 ----*/

    // 点呼詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['date'], true, function () {
            if ($('#rollcall-detail-modal-section_id').val().length > 0) {
                // 印刷用日次情報取得
                dailyApproval.postGet();
            }
        });
    });

    /*---- アルコール測定画像表示時 ----*/

    // アルコール測定画像表示時
    $(document).on('click', '[data-rollcall-alc-image-modal]', function () {
        _modal.create(this, 'rollcall-alc-image-modal', ['id']);
    });

    /*---- 記録ファイル表示時 ----*/

    // 記録ファイル表示時
    $(document).on('click', '[data-record-modal]', function (e) {
        e.stopPropagation();
        _modal.create(this, 'record-modal', ['id']);
    });

    /*---- 記録ファイル表示終了時 ----*/

    // 記録ファイル表示終了時
    $(document).on('hidden.bs.modal', '#rollcall-record-modal', function() {
        $('#rollcall-record-file').get(0).pause();
        $('#rollcall-record-file').get(0).currentTime = 0;
    });

    /*---- 記録ファイルダウンロード時 ----*/

    // 記録ファイル表示時
    $(document).on('click', '[data-download-modal]', function (e) {
        const action = $(this).data('download');
        location.href = action;
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        if ($(e.target).hasClass('file-download')) {
            return;
        }
        var modalId = create_id($(this).data('setting-modal'));

        let callback = function () {
            $(modalId + ' select.driver-select').trigger('change');
            // 選択会社変更時には実施営業所の表示/非表示を判定
            $(document).on('change', 'select.company-select', function(e) {
                const selectedCompany = $(this).find('option:selected');
                if (selectedCompany.length > 0 && selectedCompany.val() !== '') {
                    const select = $(this).parents('form').find('select.implement-section-select');
                    const datas = {'company_id': selectedCompany.val()};
                    const action = $(select).data('action_is_implement_section_displayed');
                    _ajax.post(action, datas, function (results) {
                        const target = $('#implement-section-form-group');
                        if (results.result == true) {
                            target.toggleClass('d-none', false);
                            select.prop('disabled', false);
                        } else {
                            target.toggleClass('d-none', true);
                            select.prop('disabled', true);
                        }
                    })
                }
            });
        };
        // サーバー側に渡すデータキー
        let keys = ['id', 'user_id', 'holding_id', 'company_id', 'section_id', 'implement_section_id', 'vehicle_id', 'relation_id', 'date', 'type'];

        if (modalId === '#rollcall-print-select-modal') {
            // 個別印刷設定モーダル
            let searchForm = $('#search-form');
            let datas = [];
            // 印刷用の項目を追加（統括運行管理者・運行管理者・補助者・天候）
            $.each(['total_print_general_id', 'total_print_operator_id', 'total_print_assistant_id', 'weather'], function(i, key) {
                if (searchForm.find('[name=' + key + ']') != null) {
                    datas.push({'name' : key, 'value' : searchForm.find('[name=' + key + ']').val()});
                }
            });
            // キー追加
            $.each(keys, function(i, key) {
                if ($(this).data(key) != null) {
                    datas.push({'name' : key, 'value' : $(this).data(key)});
                }
            });
            _modal.create_with_data(this, 'setting-modal', datas, true, function() {
                $(modalId + ' select.driver-select').trigger('change');
            });
        } else {
            _modal.create(this, 'setting-modal', keys, true, callback);
        }
    });

    /*---- 設定時 ----*/

    // 点呼設定時
    $(document).on('click', '[data-save-modal]', function () {
        // 予定点呼の手動登録の場合は送信時にdisabledを解除
        if ($('input[name="is_manual"]').length > 0) {
            $($('#rollcall-setting-modal')).find('input:not([type=hidden]),select,textarea').prop('disabled', false);
        }
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=rollcall-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
            rollcall_refresh_approval_rate();
        }, function() {
            // 送信結果がエラーだった場合、送信時に解除したdisabledを再設定
            if ($('input[name="is_manual"]').length > 0) {
                rollcall_setting_set_by_rollcall_type($('.rollcall-setting-rollcall-type:checked'));
            }
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#rollcall-setting-modal');
            $('[data-detail-modal=rollcall-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
            rollcall_refresh_approval_rate();
        });
    });

    /*---- 運行指示書変更確認時 ----*/

    // 変更確認時
    $(document).on('click', '[data-change_confirm-modal]', function () {
        _modal.create(this, 'change_confirm-modal', ['holding_id', 'company_id', 'relation_id']);
    });

    /*---- 運行指示書作成変更時 ----*/

    // 作成変更時
    $(document).on('click', '[data-change-modal]', function () {
        _modal.submit(this, 'change-modal', function(results) {
            $('[data-detail-modal=rollcall-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
            rollcall_refresh_approval_rate();
        });
    });

    // Excelの出力時
    $(document).on('click', '#rollcall_excel_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- 点呼簿設定 ----*/

    // 点呼簿設定表示時
    $(document).on('click', '[data-config-modal]', function () {
        _modal.create(this, 'config-modal', ['id', 'company_id'], true);
    });

    // 点呼簿設定保存時
    $(document).on('click', '[data-config-save-modal]', function () {
        _modal.submit(this, 'config-save-modal', function () {
            rollcall_refresh_approval_rate();
        });
    });

    // 乗務判断一覧表示時
    $(document).on('click', '[data-drive_judgement_setting_list-modal]', function () {
        _modal.create(this, 'drive_judgement_setting_list-modal', ['id', 'company_id', 'section_id']);
    });

    // 乗務判断一覧画面内の会社or営業所変更時
    $(document).on('select2:selecting', ' #rollcall-drive_judgement_setting-company_id:enabled, #rollcall-drive_judgement_setting-section_id', function(e) {
        $(this).val(e.params.args.data.id);
        if ($(this).attr('id') == 'rollcall-drive_judgement_setting-company_id') {
            $('#rollcall-drive_judgement_setting-section_id').val('');
        }
        let form = $(this).parents('form');
        _modal.update(form.data('modal_id'), form.attr('action'), form.serializeArray());
    });

    // 乗務判断設定表示時
    $(document).on('click', '[data-drive_judgement_setting_setting-modal]', function () {
        _modal.create(this, 'drive_judgement_setting_setting-modal', ['id', 'user_id', 'section_id', 'company_id']);
    });

    // 乗務判断削除確認時
    $(document).on('click', '[data-drive_judgement_setting_confirm-modal]', function () {
        _modal.create(this, 'drive_judgement_setting_confirm-modal', ['id']);
    });

    // 乗務判断削除時
    $(document).on('click', '[data-drive_judgement_setting_delete-modal]', function () {
        _modal.submit(this, 'drive_judgement_setting_delete-modal', null, null, '-form', true, function () {
            const form = $('#rollcall-drive_judgement_setting-form');
            _modal.update(form.data('modal_id'), form.attr('action'), form.serializeArray());
            rollcall_refresh_approval_rate();
        });
    });

    // 乗務判断設定保存時
    $(document).on('click', '[data-drive_judgement_setting_save]', function () {
        _modal.submit(this, 'drive_judgement_setting_save', null, null, '-form', true, function () {
            const form = $('#rollcall-drive_judgement_setting-form');
            _modal.update(form.data('modal_id'), form.attr('action'), form.serializeArray());
            rollcall_refresh_approval_rate();
        });
    });

    // 乗務判断初期設定表示時
    $(document).on('click', '[data-drive_judgement_setting_default-modal]', function () {
        _modal.create(this, 'drive_judgement_setting_default-modal', ['id', 'company_id']);
    });

    // 乗務判断初期設定保存時
    $(document).on('click', '[data-drive_judgement_setting_default_save]', function () {
        _modal.submit(this, 'drive_judgement_setting_default_save', null, null, '-form', true, function () {
            const form = $('#rollcall-drive_judgement_setting-form');
            _modal.update(form.data('modal_id'), form.attr('action'), form.serializeArray());
            rollcall_refresh_approval_rate();
        });
    });

    $(document).on('click', '.csv-download', function(){
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- 選択印刷表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-print-select-modal]', function (e) {
        if ($(e.target).hasClass('file-download')) {
            return;
        }
        var modalId = create_id($(this).data('setting-modal'));
        _modal.create(this, 'setting-modal', ['id', 'user_id', 'holding_id', 'company_id', 'section_id', 'vehicle_id', 'relation_id', 'date', 'type'], true, function() {
            $(modalId + ' select.driver-select').trigger('change');
        });
    });

    // 選択印刷
    $(document).on('click', '[data-select-print-modal]', function () {
        let modalId = create_id($(this).data('select-print-modal'));
        let form = $(modalId).find('form');
        let formId = '#' + form.attr('id');
        // 送信値を配列化
        let datas = form.serializeArray();
        let action = extend_url_query(form.attr('action'));
        // 送信前にエラーをリセット
        _error.reset()
        _ajax.confirm_submit(formId, action, function (result) {
            // エラー無ければBlobでPDFを別ウィンドウで表示
            let new_datas = datas.filter((item) => item.name !== 'print_select_display');
            _ajax.blob(action, new_datas, function (blob) {
                window.open(window.URL.createObjectURL(blob), '_blank');
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
        });
    });
}

/**
 * 詳細モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.rollcall_detail_event_set = function () {

    /*---- 点呼詳細検索時 ----*/

    const detail_list_update = function(elm)
    {
        let form = $(elm).parents('form');
        let dates = $(form).serializeArray();
        let action = $(form).attr('action');
        let modalId = $(form).data('modal_id');
        var _this = $(elm);
        _modal.update(modalId, action, dates, true, function(){
            if (_this.attr('id') == 'rollcall-detail-modal-section_id') {
                if (_this.val().length === 0) return;
                // 印刷用日次情報取得
                dailyApproval.postGet();
            }
        });
    }

    // 点呼詳細検索時
    $(document).on('change', '#rollcall-detail-modal-implement_section_search', function(e) {
        detail_list_update(this);
    });

    // 点呼詳細検索時
    $(document).on('select2:selecting', '#rollcall-detail-modal-holding_id:enabled, #rollcall-detail-modal-company_id:enabled, #rollcall-detail-modal-section_id', function(e) {
        $(this).val(e.params.args.data.id);
        if ($(this).attr('id') == 'rollcall-detail-modal-holding_id') {
            $('#rollcall-detail-modal-company_id').val('');
            $('#rollcall-detail-modal-section_id').val('');
        }
        if ($(this).attr('id') == 'rollcall-detail-modal-company_id') {
            $('#rollcall-detail-modal-section_id').val('');
        }
        detail_list_update(this);
    });

    // 点呼設定画面 営業所変更時 点呼区分表示切替
    $(document).on('select2:select', '#rollcall-setting-modal-section_id:enabled', function(e) {
        // 営業所の設定によって、点呼区分「中間」の表示を切り替える
        switchMiddleDisplay();
    });

    // 営業所の設定によって、点呼区分「中間」の表示を切り替える
    function switchMiddleDisplay() {
        let rollcallTypes = $('#rollcall-setting-modal').find('[name="rollcall_type"]');
        let middleInput = $('#rollcall-setting-modal').find('input[name="rollcall_type"][value="2"]');
        let middleLabel = middleInput.parent('label');
        let section = $('#rollcall-setting-modal-section_id').val();
        let action = rollcallTypes.first().data('action');
        if (!section || !action) {
            // 中間を表示
            middleLabel.removeClass('display-none');
            return;
        }
        // 送信
        _ajax.post(action, [
            {'name': 'expect_json', 'value': true},
            {'name': 'section_id', 'value': section},
        ], function (results) {
            results = JSON.parse(results)
            let config = results.rollcall_config;
            if (config && config.hasOwnProperty('is_middle_rollcall') && !config.is_middle_rollcall) {
                // 選択を解除
                if (middleInput.is(':checked')) {
                    middleInput.prop('checked', false);
                }
                // 中間を非表示
                middleLabel.addClass('display-none').removeClass('active');
            } else {
                middleLabel.removeClass('display-none');
            }
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    }

    /*---- 印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = $('#rollcall-detail-modal-form').serializeArray();
        let keys = [];
        let element = document.getElementById('rollcall_print_daily_download');
        $.each(datas, function(index, value) {
            element.dataset[value.name] = value.value;
            if(value.value != null && value.value != "")
                keys.push(value.name);
        });
        keys.push('date');
        // キャッシュクリア
        $(element).removeData();

        _modal.create(element, 'print-confirm-modal', keys, true, function() {
        }, 'print');
    });

    /*---- Excelの出力時 ----*/

    // Excelの出力時(日別)
    $(document).on('click', '#rollcall_excel_daily_download', function () {
        // 送信値を配列化
        let datas = $('#rollcall-detail-modal-form').serializeArray();
        let action = extend_url_query($(this).data('action'));

        post(action, datas);
    });

    /*---- 一括印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-total-print]', function () {
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // 一括印刷のキー追加
        datas.push({ name: 'is-total', value: true });
        _modal.create_with_data(this, 'print-confirm-modal', datas, true, function() {
        }, 'total-print');
    });

    /*---- 印刷確認モーダルでの印刷ボタン押下時 ----*/

    // 印刷ボタン押下時
    $(document).on('click', 'button#print-go', function () {
        // 送信値を配列化
        let datas = $('#rollcall-print-confirm-modal-form').serializeArray();
        let action = extend_url_query($(this).data('action'));

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 点呼タイプ変更時のフォーム表示 ----*/

    // 設定モーダル表示時に処理
    $(document).on('show.bs.modal', '#rollcall-setting-modal', function (e) {
        switchMiddleDisplay();
        rollcall_setting_set_by_rollcall_type($('.rollcall-setting-rollcall-type:checked'));
    });
    // トグルボタン押下時
    $(document).on('change', '.rollcall-setting-rollcall-type', function () {
        rollcall_setting_set_by_rollcall_type(this);
    });

    /*---- 日常点検詳細表示 ----*/

    // 日常点検選択ボタン押下時
    $(document).on('click', '#rollcall-setting-modal [data-daily_check-select-modal]', function (e) {
        let btnSelect = $(this);
        let btnUnSelect = $('#rollcall-setting-modal-daily_check-unselect');
        let btnDetail = $('#rollcall-setting-modal [data-daily_check-detail-modal]');
        let params = getDailyCheckParams();

        _modal.create_with_data(this, 'daily_check-select-modal', params, true, function() {
            daily_check_select_event_set(function(result){
                $('#rollcall-setting-modal-daily_check_id').val(result);
                $(btnSelect).addClass('display-none');
                $(btnUnSelect).removeClass('display-none');
                $(btnDetail).removeClass('display-none');
            });
        });
    });

    // 日常点検選択解除ボタン押下時
    $(document).on('click', '#rollcall-setting-modal-daily_check-unselect', function (e) {
        let btnSelect = $('#rollcall-setting-modal [data-daily_check-select-modal]');
        let btnUnSelect = $(this);
        let btnDetail = $('#rollcall-setting-modal [data-daily_check-detail-modal]');
        if (btnDetail.length == 0) {
            btnDetail = $('#rollcall-setting-modal [data-target="#daily_check-unaccessible-modal"]');
        }
        $('#rollcall-setting-modal-daily_check_id').val('');
        $(btnUnSelect).addClass('display-none');
        $(btnSelect).removeClass('display-none');
        $(btnDetail).addClass('display-none');
    });

    // 日常点検詳細ボタン押下時
    $(document).on('click', '#rollcall-setting-modal [data-daily_check-detail-modal]', function (e) {
        let params = getDailyCheckParams();
        _modal.create_with_data(this, 'daily_check-detail-modal', params, true);
    });

    function getDailyCheckParams()
    {
        return {
            'id' : $('#rollcall-setting-modal-daily_check_id').val(),
            'holding_id' : $('#rollcall-setting-modal-holding_id').val(),
            'company_id' : $('#rollcall-setting-modal-company_id').val(),
            'vehicle_id' : $('#rollcall-setting-modal-vehicle_id').val(),
            'date' : $('#rollcall-setting-modal-rollcalled_at').val(),
            'type' : 'result',
        };
    }

    // 日毎一覧 日常点検セル 押下時
    $(document).on('click', '.rollcall-day-table [data-daily_check-detail-modal]', function (e) {
        // バブリング停止
        e.stopPropagation();
        _modal.create(this, 'daily_check-detail-modal', ['id', 'company_id', 'vehicle_id'], true);
    });

    // アルコール検知器状況を選択後、未選択へも変更可にする
    $(document).on('click', dailyApproval.id + ' input[name="is_normal_alcohol_detector"]', function() {
        var label = $(this).parent();
        if (label.hasClass('active')) {
            setTimeout(() => {
                $(this).prop('checked', false);
                label.removeClass('active');
                $(this).trigger('change');
            }, 0);
        }
    });

    // 故障内容設定時
    $(document).on('click', '[data-malfunction-setting-modal]', function() {
        _modal.create(this, 'malfunction-setting-modal', ['id'], false, function() {
            const types = $('#rollcall-setting-modal-malfunction_types').val().split(',');
            const other = $('#rollcall-setting-modal-malfunction_other_content').val();
            $('.rollcall-setting-malfunction-type').each(function(index, element) {
                $(element).prop('checked', (types.indexOf($(element).val()) != -1));
            });
            $('#rollcall-malfunction-setting-modal-malfunction_other_content').val(other);
            $('#rollcall-malfunction-setting-modal-malfunction_other_content').attr('disabled', !$('#rollcall-malfunction-setting-modal-malfunction_type_99').prop('checked'));
        });
    });

    // その他入力時
    $(document).on('change', '#rollcall-malfunction-setting-modal-malfunction_other_content', function() {
        $('#rollcall-malfunction-setting-modal-malfunction_type_99').prop('checked', ($(this).val().length > 0));
    });
    // その他入力状態操作
    $(document).on('change', '#rollcall-malfunction-setting-modal-malfunction_type_99', function() {
        $('#rollcall-malfunction-setting-modal-malfunction_other_content').attr('disabled', !$(this).prop('checked'));
    });

    // 故障内容設定時
    $(document).on('click', '[data-malfunction-save-modal]', function() {
        let types = [];
        $('.rollcall-setting-malfunction-type').each(function(index, element) {
            $(element).is(':checked') && types.push($(element).val());
        });
        const other = $('#rollcall-malfunction-setting-modal-malfunction_other_content').val();
        $('#rollcall-setting-modal-malfunction_types').val(types.join(','));
        $('#rollcall-setting-modal-malfunction_other_content').val(other);
        _modal.submit(this, 'malfunction-save-modal', function() {
            if (!$('#rollcall-malfunction-setting-modal-id').val()) {
                $(create_id($(this).data('malfunction-save-modal'))).modal('hide');
            }
        }, function() {
            $(create_id($(this).data('malfunction-save-modal'))).modal('show');
        }, '-form', true);
    });

    // 点呼登録モーダル内の乗務判断「詳細」ボタン押下時（点呼に紐づく乗務判断全て表示）
    $(document).on('click', '[data-drive_judgement_rollcall_list-modal]', function () {
        _modal.create(this, 'drive_judgement_rollcall_list-modal', ['rollcall_id']);
    });

    // 乗務判断リストモーダル内の乗務判断「詳細」ボタン押下時（各乗務判断を表示）
    $(document).on('click', '[data-drive_judgement_setting-modal]', function () {
        _modal.create(this, 'drive_judgement_setting-modal', ['id', 'status']);
    });

    // 乗務判断理由保存時
    $(document).on('click', '[data-drive_judgement_save_modal]', function () {
        _modal.submit(this, 'drive_judgement_save_modal', function () {
            $(create_id($(this).data('drive_judgement_save_modal'))).modal('hide');
            let listTriggerButton = $('#rollcall-setting-modal-drive_judgement_result');
            let data = {'rollcall_id': listTriggerButton.data('rollcall_id'), 'drive_judgement_id': listTriggerButton.data('drive_judgement_id')};
            _modal.update(listTriggerButton.data('drive_judgement_rollcall_list-modal'), listTriggerButton.data('action'), data);
        }, function () {
            $(create_id($(this).data('drive_judgement_save_modal'))).modal('show');
        }, '-form', true);
    });
}

window.rollcall_setting_set_by_rollcall_type = function (selector) {
    let type = $(selector).val();
    let settingModalId = create_id($(selector).data('modal-id'));
    let formId = create_id($(selector).data('form-id'));
    // 業務後点呼の場合
    if (type == '3') {
        $(formId).find('.only-type-3').each(function() {
            $(this).removeClass('display-none');
        });
        $(formId).find('.only-type').each(function() {
            $(this).addClass('display-none');
        });
        $(formId).find('.only-type-1').each(function() {
            $(this).addClass('display-none');
        });
        /*
        点呼方法：自動の時は、「運行状況内容」「交替運転者連絡」入力項目非表示
         */
        let detail = $('#operation_status_detail-block');
        let notice = $('#alternate_driver_notice-block');
        // 初期表示
        if ($('input[name="rollcall_method_type"]:checked').val() === '4') {
            detail.hide();
            notice.hide();
        }
        // 切り替え時
        $('input[name="rollcall_method_type"]').on('change', function(e) {
            // 自動選択時は、運行状況内容と交代運転者連絡は非表示となる
            if ($(this).val() === '4') {
                detail.find('input').val('');
                detail.hide();
                notice.find('input').val('');
                notice.hide();
            } else {
                detail.show();
                notice.show();
            }
        });
        // 予定実績の入力項目disabled制御（予定は、「点呼日時」「指示事項」「点呼執行者」「点呼方法」以外入力項目disabled）
        setDisabledByExpectation(type);
    } else if (type == '1') {
        // 乗務前点呼
        $(formId).find('.only-type-3').each(function() {
            $(this).addClass('display-none');
        });
        $(formId).find('.only-type').each(function() {
            $(this).removeClass('display-none');
        });
        $(formId).find('.only-type-1').each(function() {
            $(this).removeClass('display-none');
        });
        // 予定実績の入力項目disabled制御（予定は、「点呼日時」「指示事項」「点呼執行者」「点呼方法」以外入力項目disabled）
        setDisabledByExpectation(type);
        // 点呼方法 切り替え時
        $('input[name="rollcall_method_type"]').on('change', function(e) {
            // 指示事項（選択形式・テキスト形式）の表示切替
            setInstructionForBefore($('input[name="rollcall_method_type"]:checked').val());
        });
    } else {
        $(formId).find('.only-type-3').each(function() {
            $(this).addClass('display-none');
        });
        $(formId).find('.only-type').each(function() {
            $(this).removeClass('display-none');
        });
        $(formId).find('.only-type-1').each(function() {
            $(this).addClass('display-none');
        });
    }

    // 予定実績の入力項目disabled制御（乗務前・乗務後）
    function setDisabledByExpectation(type)
    {
        let expectation_input_name = 'is_expectation';
        let is_expectation = $(formId).find('input[name="is_expectation"]');
        if (is_expectation.length === 0) {
            // 乗務前
            is_expectation = $(formId).find('input[name="before_is_expectation"]');
            expectation_input_name = 'before_is_expectation';
        }
        if (is_expectation.length === 0) {
            return;
        }
        // 点呼方法（自動・対面・TEL etc...）
        let method = is_expectation.data('method'); // default method_type
        let expectation = {
            init: function() {
                // 表示初期化
                // 点呼区分（前・中間・後）
                if ($('input[name="rollcall_type"]:checked').val() !== type) {
                    $('input[name="rollcall_type"]').parent('label').removeClass('active');
                    $('input[name="rollcall_type"]:checked').parent('label').addClass('active', true);
                }
                // 追加ボタンからの予定点呼登録時
                if (!$('input[name="id"]').val() && !$('input[name="relation_id"]').val() && $('input[name="rollcall_type"]:checked').val() != 1) {
                    // 点呼区分が1（乗務前）ではない場合は乗務前に変更する
                    $('input[name="rollcall_type"][value="1"]').prop('checked', true).trigger('change');
                    $('input[name="rollcall_type"]').parent('label').removeClass('active');
                    $('input[name="rollcall_type"][value="1"]').parent('label').addClass('active', true);
                }
                // 点呼方法（自動・対面・TEL etc...）
                if ($('input[name="rollcall_method_type"]:checked').val() !== method) {
                    $('input[name="rollcall_method_type"]').parent('label').removeClass('active');
                    $('input[name="rollcall_method_type"]:checked').parent('label').addClass('active', true);
                }
                // 追加ボタンからの予定点呼登録時
                if (!$('input[name="id"]').val() && !$('input[name="relation_id"]').val()) {
                    $('input[name="rollcall_type"]').prop('disabled', true);
                // それ以外の予定点呼登録時
                } else {
                    // 各フォームをdisabledに
                    $(formId).find('input:not([type=hidden]),select,textarea').prop('disabled', true);
                }

                // 乗務前
                if (type == '1') {
                    // 日常点検・故障内容入力ボタンをdisabledに
                    $(formId).find('button[data-daily_check-select-modal]').prop('disabled', true);
                    $(settingModalId).find('button[data-malfunction-setting-modal]').prop('disabled', true);
                    // 指示事項（選択形式・テキスト形式）の表示切替
                    setInstructionForBefore($('input[name="rollcall_method_type"]:checked').val());
                }

                // 予定時の入力可能項目
                $('input[name="is_expectation"]').prop('disabled', false);
                $('input[name="before_is_expectation"]').prop('disabled', false);
                $('input[name="rollcalled_at"]').prop('disabled', false);
                $('input[name="rollcall_method_type"]').prop('disabled', false);
                $('select[name="authorizer_id"]').prop('disabled', false);
                $('textarea[name="instructions_for_after"]').prop('disabled', false);
                // 日付重複チェックにuser_idが必要disabled時はhiddenで保持する
                $('#rollcall-setting-modal-user_id_hidden').prop('disabled', false);
            },
        }
        // 初期表示（予定）
        if ($('input[name="' + expectation_input_name + '"]:checked').val() === '1') {
            expectation.init();
        }
        // 予定/実績切替
        is_expectation.off();
        is_expectation.on('change', function() {
            let methodTypeChecked = $('input[name="rollcall_method_type"]:checked').val();
            // 予定で「IT・遠隔・自動」がチェックされていて、実績に切り替えてチェックが外れ、再度予定へ戻った場合undefinedになる為、デフォルトの値を設定
            if (typeof(methodTypeChecked) === 'undefined') {
                $('input[name="rollcall_method_type"][value='+ method +']').prop('checked', true).trigger('change');
            }

            // 実績
            if ($(this).val() === '0') {
                // 使用可能に変更
                $(formId).find('input:disabled,select:disabled,textarea:disabled').prop('disabled', false);
                // 点呼区分は変更不可
                $('input[name="rollcall_type"]').prop('disabled', true);
                // 画面での実績登録時、点呼方法「IT」「自動」「遠隔」選択不可・選択解除(対面:1 TEL:2のみ可)
                $('input[name="rollcall_method_type"]').each(function(){
                    if ($(this).val() > '2') {
                        $(this).prop('disabled', true).parent('label').addClass('d-none');
                        $(this).prop('checked', false).trigger('change');
                    }
                });
                // 乗務前
                if (type == '1') {
                    // 日常点検・故障内容入力ボタンを使用可能に戻す
                    $(formId).find('button[data-daily_check-select-modal]').removeAttr('disabled');
                    $(settingModalId).find('button[data-malfunction-setting-modal]').removeAttr('disabled');
                    // 指示事項（選択形式・テキスト形式）の表示切替
                    setInstructionForBefore($('input[name="rollcall_method_type"]:checked').val());
                }
                // 日付重複チェックに使用するhiddenのuser_idは無効(disabled)にしておく
                $('#rollcall-setting-modal-user_id_hidden').prop('disabled', true);
                $('input[name="is_manual"]').val(0);
                // minDateとmaxDateの再設定
                const rollcalled_at = $('input[name="rollcalled_at"]');
                rollcalled_at.data('datetimepicker').minDate(false);
                rollcalled_at.data('datetimepicker').maxDate(rollcalled_at.data('now'));
                // 追加ボタンからの点呼登録時
                if (!$('input[name="id"]').val() && !$('input[name="relation_id"]').val()) {
                    $('input[name="rollcall_type"]').prop('disabled', false);
                }
            } else {
                // 予定：初期表示
                expectation.init();
                // 点呼方法制御を元に戻す
                $('input[name="rollcall_method_type"]').each(function() {
                    $(this).prop('disabled', false).parent('label').removeClass('d-none');
                    if ($(this).val() === methodTypeChecked) {
                        $(this).prop('checked', true).trigger('change');
                    }
                });
                $('input[name="is_manual"]').val(1);
                // minDateとmaxDateの再設定
                const rollcalled_at = $('input[name="rollcalled_at"]');
                rollcalled_at.data('datetimepicker').minDate(rollcalled_at.data('min-date') || false);
                rollcalled_at.data('datetimepicker').maxDate(rollcalled_at.data('max-date') || false);
            }
        })
    }

    // 乗務前用 指示事項（選択形式・テキスト形式）の入力切替
    function setInstructionForBefore(method_type)
    {
        if (method_type == '4') {
            // 点呼方法：自動
            // テキスト形式 表示
            $(settingModalId).find('[name=instructions_for_before]').prop('disabled', false).closest("[class*='only-type']").removeClass('display-none');
            // 選択形式 非表示
            $(settingModalId).find('[name="new_instructions[]"]').prop('disabled', true).closest("[class*='only-type']").addClass('display-none');
            // multiple-selectを使用不可に変更
            $(settingModalId + ' .instruction-multiple-select').find('input').prop('disabled', true);
        } else {
            // 点呼方法：自動以外
            // テキスト形式 非表示
            $(settingModalId).find('[name=instructions_for_before]').prop('disabled', true).closest("[class*='only-type']").addClass('display-none');
            // 選択形式 表示
            $(settingModalId).find('[name="new_instructions[]"]').prop('disabled', false).closest("[class*='only-type']").removeClass('display-none');
            // multiple-selectを使用可能に変更
            $(settingModalId + ' .instruction-multiple-select').find('input:disabled').prop('disabled', false);
        }
    }
}

/**
 * 点呼設定モーダル表示時のイベント設定
 */
window.rollcall_config_event_set = function () {
    /*
    業務後点呼実施予定ON/OFFによる予定関連項目制御
     */
    $(document).on('change', 'input[name="is_expectation"]', function(e) {
        let enforcer = $('select[name="expectation_enforcer_id"]');
        let hours_val = $('input[name="expectation_hours"]');
        let hours = hours_val.prev('input');
        // selectがdisabled時のhidden無効
        $('input[name="expectation_enforcer_id"]:hidden').prop('disabled', true);

        if ($(this).val() === '1') {
            enforcer.prop('disabled', false).val(enforcer.data('default')).trigger("change");
            hours.prop('disabled', false).val(hours.data('prefix') + hours.data('default') + hours.data('suffix'))
            hours_val.val(hours.data('default'));
        } else {
            enforcer.prop('disabled', true).val('').trigger("change");
            hours.prop('disabled', true).val('');
            hours_val.val('');
        }
    });

    /*
    業務前点呼実施予定ON/OFFによる予定関連項目制御
     */
    $(document).on('change', 'input[name="before_is_expectation"]', function(e) {
        let enforcer = $('select[name="before_expectation_enforcer_id"]');
        let hours_val = $('input[name="before_expectation_hours"]');
        let hours = hours_val.prev('input');
        // selectがdisabled時のhidden無効
        $('input[name="before_expectation_enforcer_id"]:hidden').prop('disabled', true);

        if ($(this).val() === '1') {
            enforcer.prop('disabled', false).val(enforcer.data('default')).trigger("change");
            hours.prop('disabled', false).val(hours.data('prefix') + hours.data('default') + hours.data('suffix'))
            hours_val.val(hours.data('default'));
        } else {
            enforcer.prop('disabled', true).val('').trigger("change");
            hours.prop('disabled', true).val('');
            hours_val.val('');
        }
    });
}
